.banner {
  display: flex;
  position: relative;
  flex-direction: column;
}

@media screen and (max-width: 749px) {
  .banner--small.banner--mobile-bottom:not(.banner--adapt) > .banner__media,
  .banner--small.banner--stacked:not(.banner--mobile-bottom):not(.banner--adapt) > .banner__media {
    height: 28rem;
  }

  .banner--medium.banner--mobile-bottom:not(.banner--adapt) > .banner__media,
  .banner--medium.banner--stacked:not(.banner--mobile-bottom):not(.banner--adapt) > .banner__media {
    height: 34rem;
  }

  .banner--large.banner--mobile-bottom:not(.banner--adapt) > .banner__media,
  .banner--large.banner--stacked:not(.banner--mobile-bottom):not(.banner--adapt) > .banner__media {
    height: 39rem;
  }

  .banner--small:not(.banner--mobile-bottom):not(.banner--adapt) .banner__content {
    min-height: 28rem;
  }

  .banner--medium:not(.banner--mobile-bottom):not(.banner--adapt) .banner__content {
    min-height: 34rem;
  }

  .banner--large:not(.banner--mobile-bottom):not(.banner--adapt) .banner__content {
    min-height: 39rem;
  }
}

@media screen and (min-width: 750px) {
  .banner {
    flex-direction: row;
  }

  .banner--small:not(.banner--adapt) {
    min-height: 42rem;
  }

  .banner--medium:not(.banner--adapt) {
    min-height: 56rem;
  }

  .banner--large:not(.banner--adapt) {
    min-height: 72rem;
  }
}

@media screen and (max-width: 749px) {
  .banner:not(.banner--stacked) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .banner--stacked {
    height: auto;
  }

  .banner--stacked .banner__media {
    flex-direction: column;
  }
}

.banner__media {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.banner__media-half {
  width: 50%;
}

.banner__media-half + .banner__media-half {
  right: 0;
  left: auto;
}

@media screen and (max-width: 749px) {
  .banner--stacked .banner__media-half {
    width: 100%;
  }

  .banner--stacked .banner__media-half + .banner__media-half {
    order: 1;
  }
}

@media screen and (min-width: 750px) {
  .banner__media {
    height: 100%;
  }
}

.banner--adapt {
  height: auto;
}

@media screen and (max-width: 749px) {
  .banner--mobile-bottom .banner__media,
  .banner--stacked:not(.banner--mobile-bottom) .banner__media {
    position: relative;
  }

  .banner--stacked.banner--adapt .banner__content {
    height: auto;
  }

  .banner:not(.banner--mobile-bottom) .banner__box {
    background-color: transparent;
    --color-foreground: 255, 255, 255;
    --color-button: 255, 255, 255;
    --color-button-text: 0, 0, 0;
  }

  .banner:not(.banner--mobile-bottom) .button--secondary {
    --color-button: 255, 255, 255;
    --color-button-text: 255, 255, 255;
    --alpha-button-background: 0;
  }

  .banner--stacked:not(.banner--mobile-bottom):not(.banner--adapt)
    .banner__content {
    position: absolute;
    height: auto;
  }

  .banner--stacked.banner--adapt:not(.banner--mobile-bottom) .banner__content {
    max-height: 100%;
    overflow: hidden;
    position: absolute;
  }

  .banner--stacked:not(.banner--adapt) .banner__media {
    position: relative;
  }

  .banner::before {
    display: none !important;
  }

  .banner--stacked .banner__media-image-half {
    width: 100%;
  }
}

.banner__media .placeholder-svg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.banner__content {
  padding: 0;
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

@media screen and (max-width: 749px) {
  .banner--mobile-bottom:not(.banner--stacked) .banner__content {
    order: 2;
  }
}

@media screen and (min-width: 750px) {
  .banner__content {
    padding-bottom: 5rem;
    padding-top: 5rem;
  }

  .banner__content--center {
    align-items: center;
  }

  .banner__content--flex-start {
    align-items: flex-start;
    padding-bottom: 15rem;
  }

  .banner__content--flex-end {
    align-items: flex-end;
    padding-top: 15rem;
  }
}

.banner__box {
  border: 0;
  padding: 4rem 3.5rem;
  position: relative;
  height: fit-content;
  align-items: center;
  text-align: center;
  width: 100%;
}

@media screen and (min-width: 750px) {
  .banner--desktop-transparent .banner__box {
    background-color: transparent;
    --color-foreground: 255, 255, 255;
    --color-button: 255, 255, 255;
    --color-button-text: 0, 0, 0;
    max-width: 89rem;
  }

  .banner--desktop-transparent .button--secondary {
    --color-button: 255, 255, 255;
    --color-button-text: 255, 255, 255;
    --alpha-button-background: 0;
  }
}

@media screen and (max-width: 749px) {
  .banner--mobile-bottom::after {
    display: none;
  }
}

.banner::after {
  content: '';
  position: absolute;
  background: #000000;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.banner__box > .banner__buttons {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.banner__box > * + .banner__buttons--multiple {
  display: flex;
  max-width: 45rem;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 750px) {
  .banner__box > * + .banner__buttons {
    margin-top: 2rem;
  }
}

.banner__content .button:last-child {
  margin-right: 0;
}

.banner__box > * + .banner__text {
  margin-top: 1.5rem;
}

@media screen and (min-width: 750px) {
  .banner__box > * + .banner__text {
    margin-top: 2rem;
  }
}

.banner__box > * + * {
  margin-top: 1rem;
}

.banner__box > *:first-child {
  margin-top: 0;
}

.banner__content .button:not(:only-child) {
  flex-grow: 1;
}

@media screen and (max-width: 749px) {
  .banner--stacked .banner__box {
    width: 100%;
  }
}

@media screen and (min-width: 750px) {
  .banner__box {
    padding: 5rem;
    width: auto;
    max-width: 71rem;
    min-width: 45rem;
  }

  .banner__box > .banner__buttons:only-child .button {
    margin-top: 0;
  }
}

@media screen and (min-width: 1400px) {
  .banner__box {
    max-width: 90rem;
  }
}

.banner__heading > *,
.banner__text > * {
  word-wrap: break-word;
}

.banner__heading {
  margin-bottom: 0;
}
